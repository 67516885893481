import api from "../_boot/baseApi";

export const getHomeCatMedias = ({commit}, payload) => {
    payload.vm.isLoading = true;
    api.get(`/api/m/media/home/cat`).then(({data}) => {
        commit("setHomeCatMedias", data);
        payload.vm.isLoading = false;
    });
};

export const getLatestMovies = ({commit, state}, payload) => {
    if (state.latestMovies.length == 0) {
        payload.vm.isLoading = true;
        api.get(`/api/m/latest/movie`)
            .then(({data}) => {
                commit('setLatestMovies', data)
                payload.vm.isLoading = false;
            })
            .catch((e) => {
                console.log(e);
                payload.vm.isLoading = false;
            });
    }
}

export const getLatestMedia = ({commit}, payload) => {
    payload.vm.isLoading = true;
    api.get(`/api/m/media/latest/0`)
        .then(({data}) => {
            console.log(data);
            commit('setLatestMedias', data)
            payload.vm.isLoading = false;
        })
        .catch((e) => {
            console.log(e);
            payload.vm.isLoading = false;
        });
}

export const getPopularMedia = ({commit}, payload) => {
    api.get(`/api/m/anime/detail/${payload.anime}/${payload.vm.$user ? payload.vm.$user.id : null}`).then(({data}) => {
        commit("setAnime", data);
    });
};

export const getSeasonMedia = ({commit}, payload) => {
    payload.vm.isLoading = true;
    api.get(`/api/m/anime/season`).then(({data}) => {
        commit("setSeasonMedias", data.data);
        payload.vm.isLoading = false;
    }).catch((e) => {
        console.log(e);
        payload.vm.isLoading = false;
    });
};

export const getUpComingMedia = ({commit}, payload) => {
    api.get(`/api/m/anime/detail/${payload.anime}/${payload.vm.$user ? payload.vm.$user.id : null}`).then(({data}) => {
        commit("setAnime", data);
    });
};

export const getMediaList = ({state, commit}, payload) => {
    let url = `/api/m/anime/list/${state.user ? state.user.id : null}?page=${payload.vm.page}&t=${payload.type}${payload.vm.$route.query.id ? "&id=" + payload.vm.$route.query.id : ""}`;
    let filterData = {
        cats: payload.cat == 'all' ? null : payload.cat,
        season: payload.season == 'all' ? null : payload.season,
        sort: payload.sort == 'all' ? null : payload.sort,
        animeStatus: payload.animeStatus == 'all' ? null : payload.animeStatus,
    };

    api.post(url, filterData).then(({data}) => {
        if (payload.vm.page > 1) {
            let oldMediaList = [...state.mediaList];
            data.data.forEach((item) => {
                oldMediaList.push(item);
            });
            commit('setMediaList', oldMediaList);
        } else {
            commit('setMediaList', data.data);
        }

        setTimeout(() => {
            payload.vm.isLoading = false;
            payload.vm.isLoadingMore = false;
        }, 1000);

        if (data.current_page == data.last_page) {
            payload.vm.hasMore = false;
        } else {
            payload.vm.hasMore = true;
            payload.vm.page = data.current_page + 1;
        }
    })
        .catch((e) => {
            console.log(e);
            payload.vm.isLoading = false;
            payload.vm.isLoadingMore = false;
        });
}

export const getLikedMedia = ({state, commit}, payload) => {
    let url = `/api/m/anime/list/${state.user ? state.user.id : null}?page=${payload.vm.page}&t=liked`;
    api.get(url).then(({data}) => {
        console.log("this liked media data----->", data.data);
        if (payload.vm.page > 1) {
            let oldMediaList = [...state.likedMediaList];
            data.data.forEach((item) => {
                oldMediaList.push(item);
                // console.log("this data----->",oldMediaList);
            });
            commit('setLikedMediaList', oldMediaList);
        } else {
            commit('setLikedMediaList', data.data);
        }

        setTimeout(() => {
            payload.vm.isLoading = false;
            payload.vm.isLoadingMore = false;
        }, 1000);

        if (data.current_page == data.last_page) {
            payload.vm.hasMore = false;
        } else {
            payload.vm.hasMore = true;
            payload.vm.page = data.current_page + 1;
        }
    })
        .catch((e) => {
            console.log(e);
            payload.vm.isLoading = false;
            payload.vm.isLoadingMore = false;
        });
}

export const getMediaDetail = ({commit, state}, payload) => {
    payload.vm.isLoading = true;
    commit("setMedia", null);
    api.get(`/api/m/anime/detail/${payload.mediaID}/${state.user ? state.user.id : null}`)
        .then(({data}) => {
            if (data.other_seasons != null) {
                data.other_seasons.unshift({
                    id: data.id,
                    title: data.title
                })
            }
            payload.vm.season = data.id;
            payload.vm.season_list = data.other_seasons;

            commit("setMedia", data);
            setTimeout(() => {
                payload.vm.isLoading = false;
            }, 100);

        }).catch(e => {
        console.log(e);
        payload.vm.isLoading = false;
    })
};

export const clearMedia = ({commit}) => {
    commit('clearMedia');
}

export const getCats = ({commit}, payload) => {
    console.log('I am called');

    payload.vm.isLoading = true;
    api.get(`/api/m/anime/cats/${payload.cat ? payload.cat : 1}`)
        .then(({data}) => {
            commit('setCats', data);
            payload.vm.isLoading = false;
        });
}

export const getSeasons = ({commit, state}, payload) => {
    if (state.seasons.length == 0) {
        payload.vm.isLoading = true;
        api.get(`/api/m/anime/seasonList`)
            .then(({data}) => {
                commit('setSeasons', data);
                payload.vm.isLoading = false;
            });
    }
}

export const getRelatedMedias = ({commit, state}, payload) => {
    payload.vm.isLoading = true;
    api.get(`/api/m/media/related/${state.media.id}`)
        .then(({data}) => {
            commit('setRelatedMedias', data);
            payload.vm.isLoading = false;
        });
}

export const getSuggestedMedia = ({commit}, payload) => {
    payload.vm.isLoadingSuggest = true;
    api.get(`/api/m/media/suggested/${payload.cat}`).then(({data}) => {
        commit("setSuggestedMedias", data.data);
        payload.vm.isLoadingSuggest = false;
    }).catch((e) => {
        console.log(e);
        payload.vm.isLoadingSuggest = false;
    });
};

export const setStatus = ({commit, state}, payload) => {

    let mediaID = state.media ? state.media.id : null;
    if (payload.media) {
        mediaID = payload.media;
    }

    api.get(`/api/m/anime/status/${mediaID}/${state.user ? state.user.id : null}/anime/${payload.action}/${payload.value}`)
        .then(({data}) => {
            commit('setStatus', payload);
            if (payload.value == 0) {
                payload.vm.$toast.warning(data.msg);
            } else {
                payload.vm.$toast.success(data.msg);
            }
        });
}


// getMedia(anime) {
//     axios
//         // .get(`/api/m/anime/detail/${anime}/${this.$user ? this.$user.id : null}`)
//         .get(`https://max.test/api/m/anime/detail/201/2`)
//         .then(({data}) => {
//             this.media = data;
//             console.log(data);
//         });
// },